/* eslint-disable import/no-cycle */
import React, { useRef } from "react"
import PropTypes from "prop-types"
import Slider from "react-slick"
import clsx from "clsx"

import { isWidthUp, isWidthDown } from "@material-ui/core/withWidth"

import ArrowRightIcon from "@material-ui/icons/ArrowRight"
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft"

import * as shortid from "shortid"

import makeStyles from "@material-ui/core/styles/makeStyles"

import useWidth from "../../services/useWidth"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../../styles/baseCarousel.scss"

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    padding: "0 10px",

    [theme.breakpoints.down("sm")]: {
      padding: "0 5px",
    },

    [theme.breakpoints.down("xs")]: {
      padding: "0 5px",
    },

    "&:focus": {
      outline: "none",
    },
  },
}))

// eslint-disable-next-line no-unused-vars
const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
  <span {...props}>{children}</span>
)

const BaseCarousel = ({
  render,
  elements,
  slidesToShowXs,
  slidesToShowSm,
  slidesToShowMd,
  slidesToShowLg,
  onNext,
  onPrev,
}) => {
  const classes = useStyles()
  const width = useWidth()
  const sliderRef = useRef(); // Create a ref


  const getSlidesToShow = () => {
    if (isWidthDown("xs", width)) {
      return slidesToShowXs
    }

    if (isWidthDown("sm", width)) {
      return slidesToShowSm
    }

    if (isWidthDown("md", width)) {
      return slidesToShowMd
    }

    return slidesToShowLg
  }

  const settings = {
    dots: false,
    infinite:
      (isWidthDown("sm", width) && elements.length > 1) ||
      (isWidthUp("sm", width) && elements.length > getSlidesToShow()),
    speed: 500,
    draggable: true,
    slidesToShow: getSlidesToShow(),
    slidesToScroll: 1,
    swipeToSlide: true,
    prevArrow: (
      <SlickButtonFix>
        <ArrowLeftIcon
          style={{
            color: "#f36f27",
            fontSize: 60,
            width: 60,
            marginTop: -15,
            marginLeft: -20,
          }}
        />
      </SlickButtonFix>
    ),
    nextArrow: (
      <SlickButtonFix>
        <ArrowRightIcon
          style={{
            color: "#f36f27",
            fontSize: 60,
            width: 60,
            marginTop: -15,
            marginLeft: -10,
          }}
        />
      </SlickButtonFix>
    ),
  }

  // Method to programmatically navigate to the next slide
  const next = () => {
    sliderRef.current.slickNext();
  };

  // Expose the next method via props if needed
  if (onNext) {
    onNext(next);
  }

  // Method to programmatically navigate to the next slide
  const prev = () => {
    sliderRef.current.slickPrev();
  };

  // Expose the next method via props if needed
  if (onPrev) {
    onPrev(prev);
  }

  return (
    <div className={`${clsx("baseCarouselWrapper", elements.length <= getSlidesToShow() ? `wide` : ``)}`}>      
      <Slider ref={sliderRef} {...settings}>
        {elements.map(el => (
          <div className={classes.cardWrapper} key={shortid.generate()}>
            {render(el)}
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default BaseCarousel

BaseCarousel.defaultProps = {
  slidesToShowXs: 1,
  slidesToShowSm: 2,
  slidesToShowMd: 3,
  slidesToShowLg: 3,
  onNext: () => { },
  onPrev: () => { },
}

BaseCarousel.propTypes = {
  elements: PropTypes.array.isRequired,
  render: PropTypes.func.isRequired,
  slidesToShowXs: PropTypes.number,
  slidesToShowSm: PropTypes.number,
  slidesToShowMd: PropTypes.number,
  slidesToShowLg: PropTypes.number,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
}
